<template>
  <div class="home" v-show="!hideView">
    <span class="version" style="font-size:12px">版本: 0.9</span>
    <div
      class="user"
      @click="toPersonal"
    >
      <img
        :src="userInfo.head_portrait"
        class="avtor"
      >
      <p>{{userInfo.nick_name}}</p>
    </div>
    <div
      v-if="safeNum == 1"
      class="functional-module"
    >
      <dl
        v-for="(item) in safeList"
        :key="item.id"
        @click="goChatPage(item.link)"
      >
        <dt><img :src="item.imgUrl"></dt>
        <dd>{{item.title}}</dd>
      </dl>
    </div>
    <div
      v-if="safeNum == 2"
      class="functional-module"
    >
      <dl
        v-for="(item) in funcModule"
        :key="item.id"
        @click="goChatPage(item.link)"
      >
        <dt><img :src="item.imgUrl"></dt>
        <dd>{{item.title}}</dd>
      </dl>
    </div>
    <!-- 飞机 -->
    <img
      :src="require('@/assets/images/home_image_satellite.png')"
      class="plane-animate"
    >
    <!-- 流星 -->
    <img
      :src="require('@/assets/images/home_image_meteor.png')"
      class="meteor1-animate"
    >
    <img
      :src="require('@/assets/images/home_image_meteor.png')"
      class="meteor2-animate"
    >
    <!-- 飞船 -->
    <p class="ufo">
      <img
        :src="require('@/assets/images/home_image_UFO.png')"
        class="ufo1-animate"
      >
      <img
        :src="require('@/assets/images/home_image_UFO_light.png')"
        class="ufo2-animate"
      >
    </p>
    <!-- 机器人 -->
    <dl class="robots">
      <dt
        class="robots1-animate"
        ref="animate"
      >
        <span>HI，有空么，和我随便聊聊呗</span>
        <img :src="require('@/assets/images/common_image_dialogue.png')">
      </dt>
      <dd>
        <img
          :src="require('@/assets/images/common_image_bebot.png')"
          class="robots2-animate"
        >
      </dd>
    </dl>
    <!-- 聊天入口 -->
    <chat-input
      pageType="1"
      @handleChat="goChatPage"
    />

    <bubble-frame
      title="点击对话框开始对话哦"
      @getChildrenFlag="getChildrenFlag"
      v-if="guideHomeFlag"
      pageType="guideHomeFlag"
    ></bubble-frame>

    <pop-up-frame
      :show="showDialog"
      @closeDialog="closeDialog"
    ></pop-up-frame>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import ChatInput from "@/components/ChatInput";
import BubbleFrame from "@/components/BubbleFrame";
import { getVipEexpiry } from "@/fetch/api/login";
import { companyDepartment, getJYBRole } from "@/fetch/api/chat.js";
import "@/assets/scss/animate.css";
import PopUpFrame from "@/components/PopUpFrame";
export default {
  components: {
    ChatInput,
    BubbleFrame,
    PopUpFrame,
  },
  computed: {
    ...mapState({
      guideHomeFlag: (state) => state.user.guideHomeFlag,
      userInfo: (state) => state.user.userInfo,
      broker_id: (state) => state.user.broker_id,
    }),
  },
  data() {
    return {
      chartVal: "",
      safeNum: localStorage.getItem("safe"),
      safeList: [
        {
          id: 1,
          imgUrl: require("@/assets/images/home_icon_knowledge.png"),
          title: "收藏夹",
          link: "/knowledge-base/list",
        },
        {
          id: 4,
          imgUrl: require("@/assets/images/home_icon_underwriting@2x.png"),
          title: "智能核保",
          link: "/chat/HeBaoChat?underwriting=1",
        },
      ],
      funcModule: [
        {
          id: 1,
          imgUrl: require("@/assets/images/home_icon_knowledge.png"),
          title: "收藏夹",
          link: "/knowledge-base/list",
        },
        //  {
        //   id:2,
        //   imgUrl: require('@/assets/images/home_icon_risk.png'),
        //   title: '风险测评',
        //   link: 'https://m.baoxianxia.com.cn/risk/index.html'
        // }, {
        //   id:3,
        //   imgUrl: require('@/assets/images/home_icon_prospectus.png'),
        //   title: '计划书',
        //   link: 'https://h5.baoxianxia.com.cn/app/businessList.html?brokerId=0c5ffe9625204158b19b173b593408ae&token=0bd5266c-a788-463f-aa35-674ef252a058'
        // },
        {
          id: 4,
          imgUrl: require("@/assets/images/home_icon_underwriting@2x.png"),
          title: "智能核保",
          link: "/chat/HeBaoChat?underwriting=1",
        },
        {
          id: 5,
          imgUrl: require("@/assets/images/turntable.png"),
          title: "问答挑战",
          link:
            "https://api-bebot.baoxianxia.com.cn/api/v1/qa_collect/page/index/",
        },
        // {
        //   id: 6,
        //   imgUrl: require("@/assets/images/insurancepolicy.png"),
        //   title: "保单识别",
        //   link: "/guaranteeSlip",
        // },
      ],
      openPhone: localStorage.getItem("bindPhoneNumberVisible") == "true",
      vipEexpiry: false, //false可以访问,true绑定手机
      showDialog: false, //false不显示 true显示
      hideView: false
    };
  },
  methods: {
    ...mapMutations(["SET_GUIDE_FLAG", "SET_HeBao_LIST"]),
    getChildrenFlag() {
      this.SET_GUIDE_FLAG({ type: "guideHomeFlag", data: false });
    },
    closeDialog() {
      this.showDialog = false;
    },
    goChatPage(link) {
      if (this.vipEexpiry) {
        this.showDialog = true;
        return;
      }
      if (link.includes("http")) {
        window.parent.location.href = link;
      } else {
        this.$router.push(link)
      }
      if (link.indexOf("underwriting") > -1) {
        this.SET_HeBao_LIST({ type: "clear", data: "" });
      }
    },
    toPersonal() {
      if (this.vipEexpiry) {
        this.showDialog = true;
        return;
      }
      this.$router.push("/personal/personalHome");
    },
    showShouldKnow(jyb_id){
      getJYBRole(jyb_id).then((roleData)=>{ //先判断角色是否显示应知应会图标
        if (roleData.code==10000 && roleData.data){
          companyDepartment(jyb_id).then((res)=>{ //再通过数据判断
            if (res.data.length > 0){
              this.funcModule.push({
                id: 7,
                imgUrl: require("@/assets/images/common-question.png"),
                title: "应知应会",
                link:
                  "/chat/QuestionChat",
              })
              this.safeList.push({
                id: 7,
                imgUrl: require("@/assets/images/common-question.png"),
                title: "应知应会",
                link:
                  "/chat/QuestionChat",
              })
            }
          })
        }
      })
    },
  },
  async beforeCreate() {
  },
  created() {
    if (this.$route.query.from === 'app') {
      this.hideView = true
      this.$router.replace({
        path: '/chat/moment',
        query: {
          from: 'app'
        }
      })
    }
  },
  mounted() {
    this.getBrokerId = setInterval(() => {
      if (localStorage.getItem("broker_id")) {
        if (localStorage.getItem("jyb_id")){
          this.showShouldKnow(localStorage.getItem("jyb_id"))
        }
        getVipEexpiry().then((res) => {
          if (res.code == 200) {
            this.vipEexpiry = !res.result.pass;
            // this.vipEexpiry=true
            console.log(this.vipEexpiry, "this.vipEexpiry");
          }
        });
        clearInterval(this.getBrokerId);
      }
    }, 100);
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  background: url("../../assets/images/home_image_background.png") no-repeat top;
  background-size: 100% 103%;
  padding-top: 0.77rem;
  padding-left: 0.59rem;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  .user {
    position: relative;
    z-index: 1;
    width: 5rem;
    height: 1.1rem;
    background-image: url("../../assets/images/home_image_name_bg.png");
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 4.29rem 1.04rem;
    display: flex;
    align-items: center;
    p {
      width: 3.5rem;
      font-size: 0.35rem;
      font-weight: 500;
      color: #ffffff;
      margin-top: 0.24rem;
      margin-left: 0.5rem;
    }
    .avtor {
      width: 1.15rem;
      height: 1.15rem;
      margin-top: -0.1rem;
      border-radius: 50%;
    }
  }
  .functional-module {
    position: absolute;
    top: 1.44rem;
    right: 0.56rem;
    width: 1.4rem;
    dl {
      text-align: center;
      margin-bottom: 0.59rem;
      dt img {
        width: 1.12rem;
        height: 1.12rem;
      }
      dd {
        font-size: 0.35rem;
        font-weight: 500;
        color: #ffffff;
        line-height: 0.48rem;
      }
    }
  }
  // 飞机
  .plane-animate {
    position: absolute;
    top: 1.31rem;
    left: -2rem;
    z-index: 0;
    width: 6.55rem;
    animation: plane 7s infinite ease;
  }
  // 流星
  .meteor1-animate {
    position: absolute;
    top: 0rem;
    right: 0rem;
    width: 1.77rem;
    height: 1.77rem;
    animation: meteor1 5s infinite ease;
  }
  .meteor2-animate {
    position: absolute;
    top: 2rem;
    right: 0rem;
    width: 1.96rem;
    height: 1.96rem;
    animation: meteor2 5s infinite ease;
  }
  // 飞船
  .ufo {
    position: absolute;
    bottom: 4.93rem;
    left: 0.05rem;
    width: 2.1rem;
    height: 2.1rem;
    .ufo1-animate {
      position: absolute;
      right: 0;
      z-index: 1;
      width: 1.59rem;
      height: 1.02rem;
      animation: ufo1 8s infinite ease;
    }
    .ufo2-animate {
      position: absolute;
      top: 0.7rem;
      left: -0.2rem;
      z-index: 0;
      width: 1.95rem;
      height: 1.78rem;
      animation: ufo2 8s infinite ease;
      // animation: ufo2 5s infinite ease;
    }
  }
  .robots {
    position: absolute;
    left: 50%;
    bottom: 21%;
    width: 4.7rem;
    height: 8rem;
    margin-left: -2.35rem;
    animation: robots 3s infinite ease;
    dt {
      position: relative;
      span {
        position: absolute;
        top: 0.67rem;
        left: 0.67rem;
        width: 3.33rem;
        font-size: 0.4rem;
        font-weight: 500;
        color: #ffffff;
        line-height: 0.56rem;
      }
    }
    dd {
      text-align: center;
    }
    .robots1-animate {
      animation: robotsMessage 3s linear;
      img {
        width: 4.67rem;
        height: 2.77rem;
      }
    }
    .robots2-animate {
      width: 4.03rem;
      height: 5rem;
    }
  }
  .version {
    float:right;
    margin-right: 0.56rem;
    color: white;
  }
}
</style>
