<template>
  <van-popup
    v-model="show"
    class="pop-up-frame"
    :round="true"
    :close-on-click-overlay="false"
  >
    <img
      :src="require('@/assets/images/delete.png')"
      alt=""
      @click="closeFrame"
    >
    <h3>BEBOT想一直帮助你<br />请绑定一下手机号吧～</h3>
    <van-button
      class="go-bind"
      @click="goBindPhone"
    >去绑定</van-button>
  </van-popup>
</template>

<script>
// import { getVipEexpiry } from "@/fetch/api/login";
export default {
  props: {
    show:{
      type: Boolean,
      default: false,
    }
  },
  methods: {
    closeFrame(){
      this.$emit('closeDialog');
    },
    goBindPhone() {
      this.$emit('closeDialog');
      this.$router.push("/personal/changePhone/add");
    }
  },
  created() {
  }
};
</script>

<style lang="scss" scoped>
.pop-up-frame {
  position: fixed;
  width: 8.13rem;
  height: 4.61rem;
  background: #ffffff;
  padding: 0.53rem;
  img {
    position: absolute;
    top: 0.4rem;
    right: 0.4rem;
    width: 0.4rem;
    height: 0.4rem;
  }
  h3 {
    font-size: 0.45rem;
    font-weight: 500;
    color: #333333;
    line-height: 0.64rem;
    text-align: center;
    margin-top: 0.6rem;
  }
  .go-bind {
    display: block;
    width: 40%;
    height: 1.12rem;
    background: #3971ff;
    border-radius: 0.56rem;
    font-size: 0.4rem;
    font-weight: 500;
    color: #ffffff;
    margin: 0 auto;
    margin-top: 0.43rem;
  }
}
</style>
