<template>
  <div class="bubble-frame">
    <div class="bubble">
      <h3>{{title}}</h3>
      <van-button class="know" @click="getChildrenFlag">知道了</van-button>
      <div class="trigon"></div>
    </div>
    <img :src="require('@/assets/images/home-flag.png')" alt="" class="chat-images-back" v-show="getChildrenFlag&&pageType=='guideHomeFlag'" />
  </div>
</template>

<script>
export default {
  props: {
    title:{
      type: String,
      default: '',
    },
    pageType:{
      type: String,
      default: '',
    }
  },
  components: {},
  methods: {
    getChildrenFlag(){
      this.$emit('getChildrenFlag')
    }
  },
  mounted() {
    
  }
};
</script>

<style lang="scss" scoped>
.bubble-frame{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 11111;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: .4);
  .bubble{
    position: fixed;
    bottom: 3.8rem;
    left: 0;
    right: 0;
    margin: auto;
    width: 9.44rem;
    min-height: 2.72rem;
    background: #4479FF;
    border-radius: 0.4rem;
    padding: .27rem;
    margin: 0 auto;
    text-align: center;
    h3{
      font-size: 0.43rem;
      color: #FFFFFF;
      margin-top: .2rem;
    }
    .know{
      width: 2.33rem;
      height: 0.91rem;
      background: #FFFFFF;
      border-radius: 0.56rem;
      font-size: 0.43rem;
      color: #4479FF;
      margin-top: .3rem;
    }
    .trigon{
      position: absolute;
      right: .8rem;
      bottom: -.4rem;
      width: 0;
      height: 0;
      border-top: .6rem solid #4479FF;
      border-right: .4rem solid transparent;
      border-left: .4rem solid transparent;
    }
  }
  .chat-images-back{
    position: fixed;
    left: 5.6%;
    bottom: 4.43%;
    width: 88.93%;
  }
}
</style>
